$(".c-slider-courses").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 15,
        nav: false,
        loop: true,
        controls: true,
        mouseDrag: true,
        responsive: {
            768: {
                gutter: 30
            },
            992: {
                items: 2
            },
            1200: {
                items: 2
            },
            1680: {
                items: 2
            }
        }
    });
});
