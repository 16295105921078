$(".c-slider-services").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 30,
        nav: false,
        loop: true,
        controls: false,
        mouseDrag: false,
        responsive: {
            768: {
                items: 3
            },
            1200: {
                items: 3
            },
            1680: {
                items: 3
            }
        }
    });
});
