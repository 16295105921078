$(".slider-quotes").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        nav: false,
        autoplay: true,
        autoplayTimeout: 12000,
        autoplayHoverPause: true,
        autoplayButton: false,
        autoplayButtonOutput: false,
        controls: false,
        mouseDrag: true,
        responsive: {
            992: {
                mouseDrag: false
            }
        }
    });
});

$(".slider-image-header").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        nav: false,
        autoplay: true,
        autoplayTimeout: 9000,
        mode: 'gallery',
        animateIn: 'fadeInDown',
        animateOut: 'fadeOutDown',
        autoplayHoverPause: true,
        autoplayButton: false,
        autoplayButtonOutput: false,
        controls: false,
        mouseDrag: true,
        responsive: {
            992: {
                mouseDrag: false
            }
        }
    });
});

