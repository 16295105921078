$(".c-slider-events").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        gutter: 15,
        nav: false,
        loop: false,
        controls: false,
        mouseDrag: true,
        responsive: {
            480: {
                items: 2
            },
            768: {
                items: 3,
                gutter: 30
            },
            992: {
                items: 4,
                mouseDrag: false
            },
            1200: {
                items: 5
            },
            1680: {
                items: 6
            }
        }
    });
});
