$(".c-usps").each(function(index, value) {
    var slider = tns({
        container: value,
        items: 1,
        slideBy: 'page',
        autoplayButtonOutput: false,
        autoplayTimeout: 9000,
        speed: 800,
        autoplay: true,
        nav: false,
        controls: false
    });
});
